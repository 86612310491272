<template>
  <div>
    <div
      style="
        position: absolute;
        background: white;
        z-index: 9;
        border: 1px solid rgb(240, 240, 240);
        width: 300px;
        border-border: 6px;
        box-sizing: border-box;
        border-radius: 6px 6px 0 0;
      "
    >
      <el-button
        type="primary"
        size="mini"
        style="margin: 10px; float: right"
        @click="handleType(1)"
        >新增分类属性</el-button
      >
    </div>
    <div class="wrapper">
      <div
        class="left"
        v-loading="treeLoading"
        style="margin-top: 50px; border-top: none"
      >
        <!-- <div class="title">
          <el-button type="primary" @click="handleType(1)">新增分类属性</el-button>
        </div> -->
        <template v-for="(attr, index) in attrData">
          <div class="tree" :key="attr.attr_type_id" v-if="attrData.length">
            <div>
              <div class="tree-title">
                {{ attr.attr_type_name }}
                <!-- <el-button
                  type="primary"
                  size="mini"
                  style="float: right; margin-top: 18px; margin-right: 15px"
                  @click="handleType(1)"
                  >新增分类属性</el-button
                > -->
              </div>
            </div>
            <el-tree
              class="tree-line"
              :indent="0"
              node-key="attr_id"
              :current-node-key="currentKey"
              :default-expand-all="true"
              :data="attr.data"
              :props="defaultProps"
              :highlight-current="index === currIndex"
              :expand-on-click-node="false"
              @node-click="handleNodeClick($event, index)"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span v-if="data.hierarchy_id === 1">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="系统默认分类不可添加子分类"
                    placement="right-end"
                  >
                    <span style="font-size: 12px; color: #999"
                      >系统默认<i class="el-icon-warning"></i
                    ></span>
                  </el-tooltip>
                </span>
              </span>
            </el-tree>
          </div>
        </template>
      </div>
      <div class="right">
        <div class="title" style="padding-left: 30px">
          <div>
            <span class="txt">{{ currAttr.attr_name }}</span>
            <i
              class="el-icon-edit"
              style="margin-right: 10px; margin-top: 3px"
              @click="handleEdit(2)"
            ></i>
            <el-popconfirm
              title="确定要删除该属性吗？"
              @confirm="handleDeleAttr"
            >
              <i
                slot="reference"
                class="el-icon-delete"
                style="margin-top: 3px"
              ></i>
            </el-popconfirm>
          </div>
          <div style="display: flex">
            <div style="display: flex">
              <el-input
                placeholder="请输入属性值名称"
                v-model="name"
                clearable
                @keyup.enter.native="handleKeydown"
              >
              </el-input>
              <el-button
                type="primary"
                @click="handleSearch"
                style="margin-left: 10px"
                >搜索</el-button
              >
            </div>
            <el-button
              type="primary"
              style="margin-left: 20px"
              @click="handleAttrVisible(1)"
              :disabled="currAttr.canAdd ? false : true"
            >
              新增分类属性值
            </el-button>
          </div>
        </div>
        <div class="table" style="border: none; border-top: 1px solid #ddd">
          <el-table
            stripe
            :data="attrValue.list"
            v-loading="loading"
            height="680"
          >
            <el-table-column
              prop="date"
              label="序号"
              width="100"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="attr_value" label="名称" align="center">
            </el-table-column>
            <el-table-column
              prop="date"
              label="图片"
              width="200"
              align="center"
            >
              <template slot-scope="scope">
                <img
                  :src="scope.row.picpath"
                  v-if="scope.row.picpath"
                  style="
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                    margin: 10px 0;
                  "
                />
                <span v-else>暂无</span>
              </template>
            </el-table-column>
            <el-table-column prop="unit_id_name" label="单位" align="center">
            </el-table-column>
            <el-table-column prop="user_name" label="添加人" align="center">
              <template slot-scope="scope">
                {{ scope.row.user_name ? scope.row.user_name : "系统默认" }}
              </template>
            </el-table-column>
            <el-table-column prop="update_time" label="更新日期" align="center">
            </el-table-column>
            <el-table-column label="操作" width="180" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleDele(scope.row)"
                  :disabled="scope.row.canDel === 1 ? false : true"
                  >删除</el-button
                >
                <!--  -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div
          class="pagina"
          style="background: white; margin-top: 20px; margin-right: 10px"
        >
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handlePage"
            layout="total, sizes, prev, pager, next, jumper"
            :page-size="$store.state.pageSizes[0]"
            :page-sizes="$store.state.pageSizes"
            :total="attrValue.total"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog
        :title="type === 1 ? '新增分类属性' : '编辑属性'"
        :visible.sync="dialogVisible"
        width="45%"
        :destroy-on-close="true"
        :before-close="handleClose2"
      >
        <el-form
          :model="form"
          label-width="130px"
          ref="form"
          :rules="rules"
          style="height: 500px; overflow-y: auto"
        >
          <el-form-item label="属性名称" required prop="attr_name">
            <el-input v-model="form.attr_name" clearable></el-input>
          </el-form-item>
          <el-form-item label="属性分组" required prop="attr_type_id">
            <el-select

              v-model="form.attr_type_id"
              placeholder="请选择"
              @change="handleChoose"
              clearable
              filterable
            >
              <template v-for="item in attrData">
                <el-option
                  :label="item.attr_type_name"
                  :value="item.attr_type_id"
                  :key="item.attr_type_id"
                ></el-option>
              </template>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="上级分类" required  prop="pid">
            <el-select v-model="form.pid" placeholder="请选择" clearable filterable="">
              <template v-for="item in select">
                <template v-if="!(type === 2 && currentKey === item.attr_id)">
                <el-option :label="item.attr_name" :value="item.attr_id" :key="item.attr_id" v-if="item.hierarchy_id !== 1">
                  <span :style="{marginLeft: item.span * 8 + 'px'}">{{item.attr_name}}</span>
                </el-option>
                </template>
              </template>
            </el-select>
          </el-form-item> -->
          <el-form-item
            label="上级分类"
            required
          >
            <SelectTree
              v-if="dialogVisible"
              :value="valueId"
              :props="props"
              :options="select"
              :clearable="isClearable"
              :accordion="isAccordion"
              @getValue="getValue($event)"
            />
          </el-form-item>
          <el-form-item
            label="属性交互"
            required
            prop="type"
            v-if="type === 1 || form.type"
          >
            <el-radio-group v-model="form.type" @change="changeRadio">
              <el-radio :label="10">文本输入框</el-radio>
              <el-radio :label="20">下拉选择框</el-radio>
              <el-radio :label="30">数值输入框</el-radio>
              <el-radio :label="40">自动计算</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="保留小数位" required prop="decimal"  v-if="form.type === 30||form.type === 40" >
            <el-select
                v-model="form.decimal"
                placeholder="请选择保留的小数位"
                filterable
                size="mini"
                style="width: 160px"
              >
                <template v-for="(item,index) in reserveDecimal">
                  <el-option :key="index" :label="reserveDecimal[index]" :value="reserveDecimal[index]">
                  </el-option>
                </template>
              </el-select>
          </el-form-item>
          <el-form-item
            label="计算公式"
            required
            prop="caclu"
            v-if="form.type === 40"
          >
            <div style="display: flex">
              <el-select
                v-model="form.arrt"
                placeholder="添加属性"
                filterable
                size="mini"
                style="width: 100px"
              >
                <template v-for="item in attrList">
                  <el-option
                    @click.native="handleAttr(item)"
                    :key="item.attr_fields"
                    :label="item.attr_name"
                    :value="item.attr_fields"
                  >
                  </el-option>
                </template>
              </el-select>
              <div style="margin-left: 10px">
                <el-popover
                  placement="bottom"
                  width="200"
                  v-model="visible">
                  <el-input  size="mini" v-model="form.caclu_number" placeholder="请输入数字，最多四位小数" type="number"></el-input>
                  <div style="text-align: right; margin-top: 10px;">
                    <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="handleTrue">确定</el-button>
                  </div>
                  <el-button slot="reference" size="mini">数字</el-button>
                </el-popover>
                <span> 运算符：</span>
                <el-button size="mini" @click="handleCaclu('+')">+</el-button>
                <el-button size="mini" @click="handleCaclu('-')">-</el-button>
                <el-button size="mini" @click="handleCaclu('*')">*</el-button>
                <el-button size="mini" @click="handleCaclu('/')">/</el-button>
                <el-button size="mini" @click="handleCaclu('(')">(</el-button>
                <el-button size="mini" @click="handleCaclu(')')">)</el-button>
                <el-button size="mini" type="danger" @click="handleReset"
                  >重置</el-button
                >
              </div>
            </div>
            <div class="compute" :style="{ color: form.caclu ? 'black' : '' }">
              {{ form.caclu ? form.caclu : "请输入计算公式" }}
            </div>
            <el-input v-model="form.caclu" style="margin-top: 10px;" />
          </el-form-item>
          <el-form-item
            label="属性拓展"
            prop="extension"
            v-if="type === 1 || form.extension.length"
          >
            <el-checkbox-group
              v-model="form.extension"
              @change="changeCheckBox"
            >
              <el-checkbox :label="10">计量单位</el-checkbox>
              <el-checkbox :label="20" :disabled="form.type !== 20"
                >图片</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            label="计量单位"
            required
            v-if="hasUnit || form.unit_id"
          >
            <el-select
              v-model="form.unit_id"
              placeholder="请选择"
              clearable
              filterable
            >
              <template v-for="(item, key) in unitList">
                <el-option :label="item" :key="key" :value="key"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="搜索交互类别" required prop="search_type">
            <el-radio-group v-model="form.search_type">
              <el-radio :label="10" :disabled="search_type_disabled"
                >文本输入框</el-radio
              >
              <el-radio :label="20" :disabled="search_type_disabled"
                >下拉选择框</el-radio
              >
              <el-radio :label="30">数值范围</el-radio>
              <el-radio :label="40" :disabled="search_type_disabled"
                >日期范围</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否是表头">
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="form.is_table"
            ></el-switch>
          </el-form-item>
          <el-form-item label="是否是搜索">
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="form.is_search"
            ></el-switch>
          </el-form-item>
          <el-form-item label="表头是否启用">
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="form.t_is_use"
            ></el-switch>
          </el-form-item>
          <el-form-item label="搜索是否启用">
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="form.s_is_use"
            ></el-switch>
          </el-form-item>
          <el-form-item label="旧料表头是否启用">
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="form.recycle_is_use"
            ></el-switch>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose2">取 消</el-button>
          <el-button
            type="primary"
            @click="submitForm('form')"
            :loading="loading2"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        :title="type === 1 ? '新增分类属性值' : '编辑属性值'"
        :visible.sync="attrVisible"
        width="30%"
        :destroy-on-close="true"
        :before-close="handleClose"
      >
        <el-form ref="form2" :model="form2" label-width="80px">
          <el-form-item label="名称" required>
            <el-input v-model="form2.attr_value" clearable></el-input>
          </el-form-item>
          <!-- <el-form-item label="计量单位" required v-if="hasUnit">
            <el-select v-model="form2.unit_id" placeholder="请选择">
              <template v-for="(item, key) in unitList">
                <el-option :label="item" :key="key" :value="key"></el-option>
              </template>
            </el-select>
          </el-form-item> -->
          <el-form-item label="图片" required v-if="hasImg">
            <el-upload
              class="avatar-uploader"
              action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
              :data="{
                directory: 'SHOP_INFO_ATTR',
              }"
              :headers="{
                authorization: token,
              }"
              :show-file-list="false"
              :on-success="handleLicenseUrlSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="form2.picpath"
                :src="form2.picpath"
                style="object-fit: cover"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="handleAttrValue" :loading="loading3"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';
import Cookies from "js-cookie";
import storage from "good-storage";
import {
  listReq,
  attrValueListReq,
  createReq,
  getAttrInfoReq,
  updateReq,
  deteAttrReq,
  getUnitListReq,
  createValueReq,
  getAttrValueInfoReq,
  updateAttrValueReq,
  attrValueDelReq,
} from "@/api/goods/goodsConfig/config";
import { getAttrList } from "@/api/goods/goodsWarehousing/warehousing";

export default {
  name: "Configs",
  data() {
    const validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请填写计算公式'));
      }
      const result = this.handleTestCaclu(value);
      if (!result) {
        callback(new Error('计算公式不正确'));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      attrList: [],
      search_type_disabled: false,
      valueId: 0,
      isClearable: true, // 可清空（可选）
      isAccordion: true, // 可收起（可选）
      props: {
        value: "attr_id",
        label: "attr_name",
        children: "children",
      },
      treeLoading: false,
      loading3: false,
      form2: {},
      token: "",
      hasUnit: false,
      hasImg: false,
      unitList: [],
      loading2: false,
      rules: {
        attr_type_id: [{ required: true, message: "请选择属性分组" }],
        // pid: [
        //   { required: true, message: '请选择上级分类' },
        // ],
        attr_name: [{ required: true, message: "请填写分类名称" }],
        type: [{ required: true, message: "请选择分类交互" }],
        decimal: [{ required: true, message: "请选择保留的小数位" }],
        search_type: [{ required: true, message: "请选择搜索交互类别" }],
        caclu: [{ validator: validatePass }],
        // extension: [
        //   { required: true, message: '请选择分类拓展' },
        // ],
      },
      select: [],
      loading: false,
      val: "",
      currAttr: {},
      type: null,
      current: {},
      attrValue: {},
      page: 1,
      page_num: this.$store.state.pageSizes[0],
      name: "",
      currIndex: null,
      attrData: [],
      currentKey: null,
      license_img: "",
      imageUrl: "",
      attrVisible: false,
      reserveDecimal:[0,1,2,3,4],//需要保留的小数位
      form: {
        pid: 0,
        attr_name: "",
        type: "",
        extension: [],
        is_table: "",
        is_search: "",
        t_is_use: "",
        s_is_use: "",
        recycle_is_use: "",
        caclu: "",
        caclu_string: "",
        caclu_field_string: "",
        decimal:""
      },
      dialogVisible: false,
      formInline: {},
      defaultProps: {
        children: "children",
        label: "attr_name",
      },
    };
  },
  watch: {
    currentKey(val) {
      this.name = "";
      this.page = 1;
      this.val = val;
      this.getattrValueList({
        attr_id: val,
        name: this.name,
        page: this.page,
        page_num: this.page_num,
      });
    },
  },
  created() {
    this.token = storage.session.get("token") || Cookies.get("token");
    this.getUnitList();
    this.getList();
    this.getAttrList();
    // this.handleEval({
    //   x: '', y: '',
    // });
  },
  methods: {
    handleTrue() {
      const form = { ...this.form };
      if (!form.caclu_number) {
        return;
      }
      if (form.caclu_number.split('.')[1] && form.caclu_number.split('.')[1].length > 4) {
        this.$message.warning('最多为四位小数。');
        return;
      }
      form.caclu += form.caclu_number;
      form.caclu_string += form.caclu_number;
      // form.caclu_field_string += form.caclu_number;
      // 前端需要的计算公式
      form.caclu_font += `Number(${form.caclu_number})`;
      this.form = form;
      this.form.caclu_number = '';
      this.visible = false;
    },
    handleEval(row) {
      const result = eval("row['x'] + row['y']");
      console.log(result);
    },
    // 验证公式
    handleTestCaclu(string) {
      // string = string.replace(/\s/g, "");

      // // 错误情况，空字符串
      // if ("" === string) {
      //   return false;
      // }

      // // 错误情况，运算符连续
      // if (/[\+\-\*\/]{2,}/.test(string)) {
      //   return false;
      // }

      // // 空括号
      // if (/\(\)/.test(string)) {
      //   return false;
      // }

      // // 错误情况，括号不配对
      // var stack = [];
      // for (var i = 0, item; i < string.length; i++) {
      //   item = string.charAt(i);
      //   if ("(" === item) {
      //     stack.push("(");
      //   } else if (")" === item) {
      //     if (stack.length > 0) {
      //       stack.pop();
      //     } else {
      //       return false;
      //     }
      //   }
      // }
      // if (0 !== stack.length) {
      //   return false;
      // }

      // // 错误情况，(后面是运算符
      // if (/\([\+\-\*\/]/.test(string)) {
      //   return false;
      // }

      // // 错误情况，)前面是运算符
      // if (/[\+\-\*\/]\)/.test(string)) {
      //   return false;
      // }

      // // 错误情况，(前面不是运算符
      // if (/[^\+\-\*\/]\(/.test(string)) {
      //   return false;
      // }

      // // 错误情况，)后面不是运算符
      // if (/\)[^\+\-\*\/]/.test(string)) {
      //   return false;
      // }

      // 错误情况，变量没有来自“待选公式变量”
      // var tmpStr = string.replace(/[\(\)\+\-\*\/]{1,}/g, "`");
      // var array = tmpStr.split("`");
      // for (var i = 0, item; i < array.length; i++) {
      //   item = array[i];
      //   if (/[A-Z]/i.test(item) && "undefined" === typeof obj[item]) {
      //     return false;
      //   }
      // }

      return true;
    },
    handleReset() {
      const form = { ...this.form };
      form.caclu = "";
      form.caclu_string = "";
      form.caclu_field_string = "";
      form.caclu_font = '';
      form.caclu_number = '';
      this.form = form;
    },
    handleAttr(item) {
      const form = { ...this.form };
      if (!form.caclu) {
        form.caclu = '';
      }
      if (!form.caclu_string) {
        form.caclu_string = '';
      }
      if (!form.caclu_field_string) {
        form.caclu_field_string = '';
      }
      if (!form.caclu_font) {
        form.caclu_font = '';
      }
      form.caclu += item.attr_name;
      form.caclu_string += "${" + item.attr_fields + "}";
      form.caclu_field_string += `${item.attr_fields},`;
      // 前端需要的计算公式
      form.caclu_font += `Number(row['${item.attr_fields}'])`;
      this.form = form;
    },
    handleCaclu(key) {
      const form = { ...this.form };
      form.caclu += key;
      form.caclu_string += key;
      form.caclu_font += key;
      this.form = form;
      console.log(this.form.caclu_font);
    },
    // 获取计算公式的添加属性
    getAttrList() {
      getAttrList({
        isCaclu: 1,
        goods_type_id: 0,
        typeofAttr: 30,
      }).then((res) => {
        if (res.code === 1) {
          this.attrList = res.data;
        }
      });
    },
    // 取值
    getValue(value) {
      console.log(value)
      const form = { ...this.form };
      form.pid = value;
      this.form = form;
    },
    // 删除属性值
    handleDele(row) {
      this.$confirm("确定要删除该属性值吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        attrValueDelReq(row.attr_value_id).then((res) => {
          if (res.code === 1) {
            this.getattrValueList({
              attr_id: this.val,
              name: this.name,
              page: this.page,
              page_num: this.page_num,
            });
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    // 改变分页条数
    handleSizeChange(page_num) {
      this.page = 1;
      this.page_num = page_num;
      this.getattrValueList({
        attr_id: this.val,
        name: this.name,
        page: this.page,
        page_num: this.page_num,
      });
    },
    changeCheckBox(val) {
      const form = { ...this.form };
      form.extension = val;
      if (form.extension.includes(10)) {
        this.hasUnit = true;
      } else {
        this.hasUnit = false;
      }
      this.form = form;
    },
    changeRadio(val) {
      const form = { ...this.form };
      form.type = val;
      if (val === 30 || val === 40) {
        form.search_type = 30;
        this.search_type_disabled = true;
      } else {
        this.search_type_disabled = false;
      }
      this.form = form;
    },
    handleAttrValue() {
      const reqObj = { ...this.form2, attr_id: this.currentKey };
      this.loading3 = true;
      if (!this.form2.attr_value_id) {
        createValueReq(reqObj)
          .then((res) => {
            if (res.code === 1) {
              this.loading3 = false;
              this.$message.success("添加成功");
              this.attrVisible = false;
              this.form2 = {};
              this.current.select = "";
              this.getattrValueList({
                attr_id: this.val,
                name: this.name,
                page: this.page,
                page_num: this.page_num,
              });
            }
          })
          .catch(() => {
            this.loading3 = false;
          });
      } else {
        updateAttrValueReq(reqObj)
          .then((res) => {
            if (res.code === 1) {
              this.loading3 = false;
              this.current.select = "";
              this.$message.success("编辑成功");
              this.attrVisible = false;
              this.form2 = {};
              this.getattrValueList({
                attr_id: this.val,
                name: this.name,
                page: this.page,
                page_num: this.page_num,
              });
            }
          })
          .catch(() => {
            this.loading3 = false;
          });
      }
    },
    getUnitList() {
      getUnitListReq().then((res) => {
        if (res.code === 1) {
          this.unitList = res.data;
        }
      });
    },
    handleDeleAttr() {
      if (!this.currentKey) {
        this.$message.error("没有可删除对象");
        return;
      }
      deteAttrReq(this.currentKey).then((res) => {
        if (res.code === 1) {
          this.$message.success("删除成功");
          this.getList();
        }
      });
    },
    handleChoose(val) {
      const attrItem = this.attrData.filter(
        (item) => item.attr_type_id === val
      )[0];
      // this.select = this.handleArr(attrItem.data);
      this.select = [...attrItem.data];
      this.select.unshift({
        attr_name: "顶级分类",
        attr_id: "0",
        pid: 0,
      });
      const form = { ...this.form };
      form.pid = "0";
      this.form = form;
    },
    handleArr(arr) {
      const newArr = [];
      function poling(arr, span) {
        arr.forEach((item) => {
          if (span || span === 0) {
            item.span = span + 1;
            // item.attr_name = '├─' + item.attr_name;
          } else {
            item.span = 0;
          }
          newArr.push(item);
          if (item.children) {
            poling(item.children, item.span);
          }
        });
      }
      // 深复制
      const aArr = JSON.parse(JSON.stringify(arr));
      poling(aArr);
      return newArr;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.form.attr_id) {
            this.loading2 = true;
            createReq(this.form)
              .then(() => {
                this.loading2 = false;
                this.$message.success("添加成功");
                this.dialogVisible = false;
                this.form = {
                  attr_type_id: "",
                  pid: 0,
                  attr_name: "",
                  type: "",
                  extension: [],
                  is_table: "",
                  is_search: "",
                  t_is_use: "",
                  s_is_use: "",
                  recycle_is_use: "",
                };
                this.getList();
              })
              .catch(() => {
                this.loading2 = false;
              });
          } else {
            if (this.form.attr_id === this.form.pid) {
              this.$message.error("不可以选择本身为上级分类");
              return;
            }
            this.loading2 = true;
            updateReq(this.form)
              .then(() => {
                this.loading2 = false;
                this.$message.success("编辑成功");
                this.dialogVisible = false;
                this.form = {
                  attr_type_id: "",
                  pid: 0,
                  attr_name: "",
                  type: "",
                  extension: [],
                  is_table: "",
                  is_search: "",
                  t_is_use: "",
                  s_is_use: "",
                  recycle_is_use: "",
                };
                this.getList();
                this.getAttrList()
              })
              .catch(() => {
                this.loading2 = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    handleKeydown(val) {
      if (val.keyCode === 13) {
        this.getattrValueList({
          attr_id: this.val,
          name: this.name,
          page: this.page,
          page_num: this.page_num,
        });
      }
    },
    handleSearch() {
      this.page = 1;
      this.getattrValueList({
        attr_id: this.val,
        name: this.name,
        page: this.page,
        page_num: this.page_num,
      });
    },
    handlePage(page) {
      this.page = page;
      this.getattrValueList({
        attr_id: this.val,
        name: this.name,
        page: this.page,
        page_num: this.page_num,
      });
    },
    async handleAttrVisible(val) {
      this.type = val;
      const res = await this.getAttrInfo();
      if (res.code === 1) {
        if (res.data.type !== 20) {
          this.$message.error("属性交互不是下拉选择框。");
          return;
        }
        const { extension } = res.data;
        if (extension.length) {
          this.hasUnit = extension.includes(10);
          this.hasImg = extension.includes(20);
        }
        this.attrVisible = true;
      }
    },
    handleClose2() {
      this.dialogVisible = false;
      this.form = {
        attr_type_id: "",
        pid: 0,
        attr_name: "",
        type: "",
        extension: [],
      };
    },
    async getAttrInfo() {
      return getAttrInfoReq(this.currentKey);
    },
    async handleType(val) {
      this.select = [];
      // if (this.currAttr.hierarchy_id === 1) {
      //   this.$message.warning('默认分类不能添加子分类');
      //   return;
      // }
      this.type = val;
      if (!this.currIndex) {
        this.currIndex = 0;
      }
      if (this.attrData[this.currIndex].data.length) {
        // this.select = this.handleArr(this.attrData[this.currIndex].data);
        this.select = [...this.attrData[this.currIndex].data];
      }
      this.select.unshift({
        attr_name: "顶级分类",
        attr_id: 0,
        pid: 0,
      });
      switch (val) {
        case 1:
          if (this.currIndex || this.currIndex === 0) {
            this.dialogVisible = true;
            this.attr_type_id = this.attrData[this.currIndex].attr_type_id;
            const form = { ...this.form };
            // form.pid = this.currAttr.attr_id;
            // this.valueId = this.currAttr.attr_id;
            form.attr_type_id = this.attr_type_id;
            if (this.currAttr.hierarchy_id === 1) {
              form.pid = "";
            }
            this.form = form;
          }
          break;
        case 2:
          if (!this.currentKey) {
            this.$message.error("没有可编辑目标");
          } else {
            const res = await this.getAttrInfo();
            if (res.code === 1) {
              this.dialogVisible = true;
              this.form = res.data;
              this.valueId = this.form.pid;
            }
          }
          break;
        default:
          console.log("默认值");
      }
    },
    async handleEdit(val) {
      this.select = [];
      // if (this.currAttr.hierarchy_id === 1) {
      //   this.$message.warning('默认分类不能添加子分类');
      //   return;
      // }
      console.log(val)
      this.type = val;
      if (!this.currIndex) {
        this.currIndex = 0;
      }
      if (this.attrData[this.currIndex].data.length) {
        // this.select = this.handleArr(this.attrData[this.currIndex].data);
        this.select = [...this.attrData[this.currIndex].data];
      }
      this.select.unshift({
        attr_name: "顶级分类",
        attr_id: 0,
        pid: 0,
      });
      switch (val) {
        case 1:
          if (this.currIndex || this.currIndex === 0) {
            this.dialogVisible = true;
            this.attr_type_id = this.attrData[this.currIndex].attr_type_id;
            const form = { ...this.form };
            form.pid = this.currAttr.attr_id;
            // this.valueId = this.currAttr.attr_id;
            form.attr_type_id = this.attr_type_id;
            if (this.currAttr.hierarchy_id === 1) {
              form.pid = "";
            }
            this.form = form;
          }
          break;
        case 2:
          if (!this.currentKey) {
            this.$message.error("没有可编辑目标");
          } else {
            const res = await this.getAttrInfo();
            if (res.code === 1) {
              this.dialogVisible = true;
              this.form = res.data;
              // this.valueId = this.form.pid;
            }
          }
          break;
        default:
          console.log("默认值");
      }
    },
    handleClose() {
      this.current.select = "";
      this.attrVisible = false;
      this.form2 = {};
    },
    changeSelect(val, row) {
      if (val === "0") {
        this.type = 2;
        this.current = row;
        getAttrValueInfoReq(this.current.attr_value_id).then((res) => {
          if (res.code === 1) {
            this.attrVisible = true;
            this.form2 = { ...res.data };
            if (res.data.unit_id) {
              this.hasUnit = true;
            } else {
              this.hasUnit = false;
            }
            if (res.data.picpath) {
              this.hasImg = true;
            } else {
              this.hasImg = false;
            }
          }
        });
      }
    },
    getattrValueList({ attr_id: currentKey, name, page, page_num }) {
      this.loading = true;
      attrValueListReq({ attr_id: currentKey, name, page, page_num })
        .then((res) => {
          res.data.list.forEach((item, index) => {
            item.index = index;
            item.select = "";
          });
          this.attrValue = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getList() {
      this.treeLoading = true;
      this.attrData = [];
      listReq()
        .then((res) => {
          if (res.code === 1) {
            this.treeLoading = false;
            this.attrData = res.data;
            for (let i = 0; i < res.data.length; i++) {
              const item = res.data[i];
              if (item.data.length) {
                this.currIndex = i;
                this.currentKey = item.data[0].attr_id;
                this.currAttr = item.data[0];
                break;
              }
            }
          }
        })
        .catch(() => {
          this.treeLoading = false;
        });
    },
    beforeAvatarUpload() {},
    handleLicenseUrlSuccess(response) {
      if (response.code === 1) {
        const form2 = { ...this.form2 };
        form2.picpath = response.data;
        this.form2 = form2;
      } else {
        this.$message.error(response.msg);
      }
    },
    handleNodeClick(data, index) {
      this.currAttr = data;
      this.currIndex = index;
      this.currentKey = data.attr_id;
    },
  },
};
</script>

<style scoped lang="less">
.compute {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 0 10px;
  box-sizing: border-box;
  color: #ccc;
  margin-top: 10px;
  position: absolute;
  z-index: 9;
  background: white;
}
/deep/ .el-table--border::after,
.el-table--group::after,
.el-table::before {
  content: "";
  position: absolute;
  background-color: #ddd;
  z-index: 1;
}
/deep/ .el-form-item {
  display: flex;
  align-items: center;
}
/deep/ .el-form-item__content {
  margin-left: 20px !important;
}
.txt {
  position: relative;
  color: black !important;
  font-weight: normal !important;
  font-size: 16px;
  &::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 15px;
    background: #2791ff;
    left: -10px;
    top: 3px;
    border-radius: 99px;
  }
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
/deep/
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #ecf5ff;
  // color: white;
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/ .el-tree-node__label {
  font-size: 16px;
}
/deep/ .el-tree-node__content {
  height: 40px;
  line-height: 40px;
}
.wrapper {
  display: flex;
  .title {
    box-sizing: border-box;
    height: 58px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #f5f6fa;
    background: linear-gradient(0deg, #f3f9ff, #ffffff);
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
    span {
      color: #2791ff;
      font-weight: bold;
      margin-right: 10px;
    }
    i {
      cursor: pointer;
      color: #999999;
    }
    &-txt {
      font-size: 16px;
      text-indent: 15px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 4px;
        height: 16px;
        background: #5494f5;
        border-radius: 2px;
        top: 5px;
        left: 0px;
      }
    }
  }
  .left {
    width: 300px;
    min-height: calc(100vh - 135px);
    max-height: calc(100vh - 135px);
    background: white;
    overflow: auto;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 0px 0px 6px 6px;
    .tree {
      &-title {
        position: relative;
        line-height: 63px;
        text-indent: 20px;
        &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 15px;
          background: #2791ff;
          left: 10px;
          top: 25px;
          border-radius: 99px;
        }
      }
    }
  }
  .right {
    border-radius: 6px;
    border: 1px solid #ddd;
    flex: 1;
    background: white;
    margin-left: 10px;
    // padding: 0 10px;
    box-sizing: border-box;
    min-height: calc(100vh - 85px);
    max-height: calc(100vh - 85px);
    overflow-y: auto;
    .form {
      padding: 10px 0;
      border-bottom: 1px solid #f5f6fa;
      &-title {
        font-size: 16px;
        font-weight: bold;
        color: #999999;
        margin-bottom: 20px;
      }
    }
    .btns {
      padding: 20px 10px;
      background: linear-gradient(0deg, #f3f9ff, #ffffff);
    }
  }
}
</style>
