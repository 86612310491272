import { post } from '@/utils/request';

const listReq = () => post({
  url: '/store/goods.AttrCtl/list',
});

const attrValueListReq = ({ attr_id, name, page, page_num }) => post({
  url: '/store/goods.AttrValueCtl/list',
  data: {
    attr_id,
    name,
    page,
    page_num,
  },
});

const createReq = (params) => post({
  url: '/store/goods.AttrCtl/create',
  data: {
    ...params,
  },
});

// 获取单条属性值信息
const getAttrInfoReq = (attr_id) => post({
  url: '/store/goods.AttrCtl/getAttrInfo',
  data: {
    attr_id,
  },
});

// 编辑属性
const updateReq = (params) => post({
    url: '/store/goods.AttrCtl/update',
    data: {
      ...params,
    },
  });

// 删除属性
const deteAttrReq = (attr_id) => post({
  url: '/store/goods.AttrCtl/attr_del',
  data: {
    attr_id,
  },
});

// 获取计量单位
const getUnitListReq = () => post({
  url: '/store/goods.AttrCtl/getUnitList',
});

// 新增属性值
const createValueReq = ({ attr_id, attr_value, picpath, unit_id, }) => post({
  url: '/store/goods.AttrValueCtl/create',
  data: {
    attr_id,
    attr_value,
    picpath,
    unit_id,
  },
});

// 获取某条属性值
const getAttrValueInfoReq = (attr_value_id) => post({
  url: '/store/goods.AttrValueCtl/getAttrValueInfo',
  data: {
    attr_value_id,
  },
});

const updateAttrValueReq = ({ attr_value_id, attr_id, attr_value, picpath, unit_id, }) => post({
  url: '/store/goods.AttrValueCtl/update',
  data: {
    attr_value_id,
    attr_id,
    attr_value,
    picpath,
    unit_id,
  },
});

// 删除属性值
const attrValueDelReq = (attr_value_id) => post({
  url: '/store/goods.AttrValueCtl/attr_value_del',
  data: {
    attr_value_id,
  },
});

export {
  listReq,
  attrValueListReq,
  createReq,
  getAttrInfoReq,
  updateReq,
  deteAttrReq,
  getUnitListReq,
  createValueReq,
  getAttrValueInfoReq,
  updateAttrValueReq,
  attrValueDelReq,
};
